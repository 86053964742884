<template>
  <div class="fill-height py-3 py-md-10">
    <v-container>
      <h1 class="cust-color mb-3">CONTACT US</h1>
      <p>
        <span class="font-weight-bold">Address : </span> Hello Custom 258/6
        พระราม 3ที่12 แขวง บางโคล่ เขต บางคอแหลม กรุงเทพมหานคร 10120
      </p>
      <p><span class="font-weight-bold">Tels : </span> Tel: +66 95 689 4280</p>
      <p>
        <span class="font-weight-bold">Email : </span> hellocst209@gmail.com
      </p>
      <p>
        <span class="font-weight-bold">Office Hours : </span> 7 Days a week from
        9:00 am to 7:00 pm
      </p>
      <div class="row">
        <div class="col-12 text-center">
          <div class="video-container gmap_canvas">
            <iframe
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=HelloCus&t=&z=17&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: `Thai bike parts`,
    titleTemplate: 'Contact Us | %s'
  }
}
</script>

<style>
</style>